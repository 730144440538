.main-search-bar {
  width: 100%;
  font-size: 0.5rem;
                
}

.express-payment {

    width: 100%;
    font-size: 0.5rem;
    margin: 0.2rem !important;   
    padding: 0.2rem !important;  
}

.welcome-msg {

  width: 100%;
  font-size: 0.7rem;
  margin-top: 0.5rem !important;
  margin-left: 0.5rem !important;
}



@include media-breakpoint-up(sm) {
  .main-search-bar {
    width: 100%;
    font-size: 0.5rem;

    padding: 0.4rem !important;             
  }
  
  .express-payment {
  
    width: 100%;
    font-size: 0.5rem;
    margin: 0.2rem !important;   
    padding: 0.4rem !important;
  }

  .welcome-msg {

    width: 100%;
    font-size: 0.7rem;
    margin: 0.5rem;
  }

}

@include media-breakpoint-up(md) {
  .main-search-bar {
    width: 100%;
    font-size: 0.8rem;
                          
  }
  
  .express-payment {
  
    width: 100%;
    font-size: 0.8rem;
  }

  .welcome-msg {

    width: 100%;
    font-size: 0.8rem;
    margin-top: 1.5rem !important;
  }

}

@include media-breakpoint-up(lg) {
  .main-search-bar {
    width: 100%;
    font-size: 1rem;
                          
  }
  
  .express-payment {
  
    width: 100%;
    font-size: 1rem;
  }

  .welcome-msg {

    width: 100%;
    font-size: 1rem;
    margin-top: 1.5rem !important;
  }

}